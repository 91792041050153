import _ from "lodash";
import { Chart } from "react-google-charts";
import { useLoaderData } from "react-router";
import Panel from "~/components/panel";
import type { DynamicMetricsQuery } from "~/types/api";
import { addMonths, formatDate, parseDate } from "~/utils/dates";

type Props = {
  quarter: string;
};

export default function CEOHeadCount({ quarter }: Props) {
  const { dynamicMetrics } = useLoaderData<{
    dynamicMetrics: DynamicMetricsQuery["dynamicMetrics"];
  }>();

  const items: (string | number | { role: string })[][] = [
    [
      "Label",
      "Leaders",
      { role: "annotation" },
      { role: "style" },
      "Managers",
      { role: "annotation" },
      { role: "style" },
      "Experts",
      { role: "annotation" },
      { role: "style" }
    ]
  ];
  const dates = _.uniq(
    dynamicMetrics
      .filter((m) => m.name?.startsWith("Head Count:"))
      .map((m) => m.date)
      .filter(Boolean)
  ).sort();

  for (const date of dates) {
    const leaders =
      dynamicMetrics.find(
        (m) => m.date === date && m.name === "Head Count: Leaders"
      )?.count || 0;
    const managers =
      dynamicMetrics.find(
        (m) => m.date === date && m.name === "Head Count: Managers"
      )?.count || 0;
    const experts =
      dynamicMetrics.find(
        (m) => m.date === date && m.name === "Head Count: Experts"
      )?.count || 0;
    items.push([
      formatDate(date, { format: "MMM YYYY" }),
      leaders,
      `Leaders: ${leaders}`,
      "#5cb85c",
      managers,
      `Managers: ${managers}`,
      "#337ab7",
      experts,
      `Experts: ${experts}`,
      "#f0ad4e"
    ]);
  }

  const maxValue =
    _.sortBy(items.slice(1).flatMap((i) => [i[1], i[4], i[7]])).at(-1) || 0;

  const months = [
    parseDate(quarter),
    addMonths(parseDate(quarter), 1),
    addMonths(parseDate(quarter), 2)
  ];

  return (
    <div>
      <Panel className="mb-0">
        <Panel.Header title="Our Team" titleClassName="text-center" />
        <Chart
          height="200px"
          width="auto"
          chartType="BarChart"
          data={items}
          options={{
            chartArea: {
              top: 15,
              right: 15,
              bottom: 15,
              left: 70,
              height: "100%",
              width: "100%"
            },
            bar: {
              groupWidth: "90%"
            },
            hAxis: {
              minValue: 0,
              viewWindow: {
                min: 0,
                max: maxValue
              },
              ticks: [],
              textPosition: "none"
            },
            legend: "none"
          }}
        />
      </Panel>
    </div>
  );
}
