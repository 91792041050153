import { useLoaderData } from "react-router";
import invariant from "tiny-invariant";
import Heading from "~/components/heading";
import IndividualBoard from "~/components/kanban/individual-board";
import List from "~/components/list";
import QuarterPicker from "~/components/quarter-picker";
import ProspectMeetings from "~/components/sales/gauge-prospect-meetings";
import CEOHeadCount from "~/components/scoreboard/ceo-head-count";
import IndividualRocks from "~/components/scoreboard/individual-rocks";
import type { loader } from "~/routes/_index/route";
import GenericBarChart from "~/routes/management.scoreboard/chart-generic-bar";
import { useCurrentUser } from "~/utils/auth";

export default function DashboardIT() {
  const loaderData = useLoaderData<typeof loader>();
  const currentUser = useCurrentUser();
  const { dashboard, quarter } = loaderData;
  invariant(dashboard === "President", "Dashboard data doesn't match");
  return (
    <div>
      <Heading title="CEO Scoreboard">
        <QuarterPicker
          name="quarter"
          redirect
          defaultValue={quarter}
          isClearable={false}
        />
      </Heading>
      <div className="space-y-4">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 items-start">
          <GenericBarChart
            barLabel="EBITDA"
            chartLabel="EBITDA $"
            goalField="EBITDA $: Goal"
            actualField="EBITDA $"
            format="Accounting"
            height="200px"
            tooltip={
              <List single>
                <li>
                  Actual: Shipped invoices (only accurate for completed months)
                </li>
                <li>Goal: Gross Sales goal on the Finance KPIs</li>
              </List>
            }
          />
          <GenericBarChart
            barLabel="Invoice Total"
            chartLabel="Invoice Totals"
            goalField="Invoice Totals: Goal"
            actualField="Invoice Totals"
            format="Accounting"
            height="200px"
            tooltip={
              <List single>
                <li>Actual: Net Operating Profit (EBITDA) from Finance KPIs</li>
                <li>Goal: Goal on the Finance KPIs</li>
              </List>
            }
          />
          <GenericBarChart
            barLabel="Investment"
            chartLabel="Investment $"
            goalField="Investment $: Goal"
            actualField="Investment $"
            format="Accounting"
            height="200px"
            tooltip={
              <List single>
                <li>Actual: Investment/L&M from Finance KPIs</li>
                <li>Goal: Goal on the Finance KPIs</li>
              </List>
            }
          />
          <GenericBarChart
            barLabel="CEO Publications"
            chartLabel="CEO Publications"
            goalField="CEO Publications: Goal"
            actualField="CEO Publications"
            format="Count"
            height="200px"
            tooltip={
              <List single>
                <li>Actual: Number of Publications from CEO KPIs</li>
                <li>Goal: Goal on the CEO KPIs</li>
              </List>
            }
          />
          <CEOHeadCount quarter={quarter} />
          <ProspectMeetings
            quarter={quarter}
            name="Prospect Meetings: President"
            userId={currentUser.id}
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <IndividualBoard />
          </div>
          <div>
            <IndividualRocks />
          </div>
        </div>
      </div>
    </div>
  );
}
